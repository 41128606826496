<template>
  <div>
    <v-form ref="templateForm">
      <p class="title">1. {{ $t('templates.name') }}</p>
      <v-text-field v-model="innerTemplate.name" label="Template Name" :rules="[rules.required]" />

      <p class="title">2. {{ $t('templates.description') }}</p>
      <v-textarea v-model="innerTemplate.description" label="Template Description" />

      <p class="title mb-0">3. {{ $t('templates.ownerOrganization') }}</p>
      <span class="text-caption">{{ $t('templates.caption.ownerOrganization') }}</span>
      <v-autocomplete
        v-model="innerTemplate.owner_organization_id"
        :items="orgOptions"
        label="Client Organization"
        outline
        :disabled="!isCreate"
        :rules="[rules.required]"
      />

      <p class="title mb-0">4. {{ $t('templates.permissionScope') }}</p>
      <span class="text-caption">{{ $t('templates.caption.permissionScope') }}</span>
      <v-select
        v-model="innerTemplate.permission_scope"
        :items="permissionScopeOptions"
        label="Permission Scope"
        outline
        :rules="[rules.required]"
      />

      <p class="title mb-0">5. {{ $t('templates.hostURL') }}</p>
      <span class="text-caption">{{ $t('templates.caption.hostURL') }}</span>
      <v-text-field
        v-model="innerTemplate.host_url"
        label="Host URL"
        :rules="[rules.required, rules.url]"
      />

      <p class="title mb-0">6. {{ $t('templates.dataSourceType') }}</p>
      <span class="text-caption">{{ $t('templates.caption.dataSourceType') }}</span>
      <v-select
        v-model="innerTemplate.source_type"
        :items="sourceTypeOptions"
        :disabled="!isCreate"
        label="Source Type"
        outline
        :rules="[rules.required]"
      />

      <p class="title mb-0">7. {{ $t('templates.resultSchemaType') }}</p>
      <span class="text-caption">{{ $t('templates.caption.resultSchemaType') }}</span>
      <v-select
        v-model="innerTemplate.result_schema_type"
        :items="schemaTypeOptions"
        label="Schema Type"
        outline
        :disabled="!isCreate"
        :rules="[rules.required]"
      />

      <p class="title mb-0">8. {{ $t('templates.resultSchema') }}</p>
      <span class="text-caption">{{ $t('templates.caption.resultSchema') }}</span>
      <tree-view
        v-if="innerTemplate.result_schema"
        :data="innerTemplate.result_schema"
        :options="{ maxDepth: 3 }"
      />
      <v-file-input
        v-if="isCreate"
        accept="application/json"
        label="Schema File Input"
        :error-messages="resultSchemaFileErrors"
        @click="(event) => (event.target.value = null)"
        @change="onFileSelected"
      />

      <p class="title mb-0">9. {{ $t('templates.isThereFileInResult') }}</p>
      <span class="text-caption">{{ $t('templates.caption.isThereFileInResult') }}</span>
      <v-checkbox
        v-model="innerTemplate.file_result_enabled"
        :label="`${innerTemplate.file_result_enabled.toString()}`"
        :disabled="!isCreate"
      />

      <p class="title mb-0">10. {{ $t('templates.enable') }}</p>
      <span class="text-caption">{{ $t('templates.caption.enable') }}</span>
      <v-checkbox v-model="innerTemplate.enabled" :label="`${innerTemplate.enabled}`" />

      <p class="title mb-0">11. {{ $t('templates.platformDatasetType') }}</p>
      <span class="text-caption">{{ $t('templates.caption.platformDatasetType') }}</span>
      <v-text-field v-model="innerTemplate.platform_dataset_type" label="Dataset type" />

      <div class="mt-3 text-right">
        <v-btn class="mr-2" @click="$emit('cancel')">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="teal" dark @click="submit">
          {{ isCreate ? $t('common.create') : $t('common.save') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import validation from '../../utils/validation'

const ORG_LIST_MAX = 300

export default {
  props: {
    template: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      innerTemplate: null,
      permissionScopeOptions: [
        {
          text: 'PUBLIC',
          value: 1,
        },
        {
          text: 'PRIVATE',
          value: 2,
        },
      ],
      sourceTypeOptions: [
        {
          text: 'Image',
          value: 1,
        },
        {
          text: 'Video',
          value: 2,
        },
        {
          text: 'Audio',
          value: 3,
        },
        {
          text: 'Text',
          value: 4,
        },
      ],
      schemaTypeOptions: [
        {
          text: 'FREE',
          value: 1,
        },
        {
          text: 'SELECTABLE',
          value: 2,
        },
      ],
      resultSchemaFileErrors: [],
      rules: {
        required: (value) => !!value || 'Required.',
        min: (v) => v.length >= 6 || 'Min 6 characters',
        url: (value) => validation.validURL(value) || 'Invalid URL',
      },
    }
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
    }),
    orgOptions() {
      return this.clients.map((org) => ({
        text: org.name,
        value: org.id,
      }))
    },
    isCreate() {
      return !this.innerTemplate.id
    },
  },
  watch: {
    template: {
      handler(newValue) {
        this.innerTemplate = JSON.parse(JSON.stringify(newValue))
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchClients']),
    submit() {
      const valid = this.$refs.templateForm.validate()
      if (!valid) {
        return
      }
      this.$emit('submit', this.innerTemplate)
    },
    onFileSelected(f) {
      if (f == null) {
        // if file selection is cleared
        this.innerTemplate.result_schema = null
        this.resultSchemaFileErrors = []
        return
      }
      const reader = new FileReader()
      reader.onload = () => {
        try {
          this.innerTemplate.result_schema = JSON.parse(reader.result)
        } catch (e) {
          this.resultSchemaFileErrors = [e.toString()]
        }
      }
      reader.onerror = (e) => {
        this.resultSchemaFileErrors = [e.toString()]
      }
      reader.readAsText(f)
    },
  },
  mounted() {
    this.fetchClients({ perPage: ORG_LIST_MAX })
  },
}
</script>
